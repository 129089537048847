@import "../../../style/mixin";

.cookie {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 28px 60px;

  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }

  p,
  i,
  pre {
    font-size: 14px;
    line-height: 18px;
    text-wrap: balance;
  }

  b {
    font-weight: 900;
    color: var(--black);
  }

  b.title {
    display: block;
    font-size: 18px;
    line-height: 60px;
  }

  table {
    border-collapse: collapse;
  }
  table,
  th,
  td {
    border: 1px solid;
    font-size: 16px;
    padding: 4px 8px;
  }

  ul.style-circle {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    li {
      margin-left: 20px;
    }
  }

  @include sm {
    gap: 2rem;
    padding: 3.2rem 2.8rem 6rem;

    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.8rem;
    }

    p,
    i {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    b.title {
      font-size: 1.8rem;
      line-height: 6rem;
    }

    ul.style-circle {
      margin-top: 1.2rem;
      gap: 0.4rem;
      li {
        margin-left: 2rem;
      }
    }
  }
}
